<template>
  <div class="p-1" style="min-width: 100px">
    <div class="row col-12 m-auto p-0" style="border-radius: 3px; overflow: hidden;">
      <div class="col-9 m-0 p-2" :style="{backgroundColor : displayTextBgColor, color : displayTextColor}">{{ displayText }}</div>
      <div class="col-3 m-0 p-0" :style="{backgroundColor: displayTextBgColor}">
        <div class="rounded-left m-0 p-2" :style="{backgroundColor : displayCountBgColor, color : displayCountColor}">
          {{ displayCount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'DataCounter',
  props : {
    displayText : {
      type : String,
      default : 'Data'
    },
    displayTextColor : {
      type : String,
      default : '#435464'
    },
    displayTextBgColor : {
      type : String,
      default : '#e1e1e1'
    },
    displayCount : {
      type : Number,
      default : 0
    },
    displayCountColor : {
      type : String,
      default : '#435464'
    },
    displayCountBgColor : {
      type : String,
      default : '#e1e1e1'
    },
  }
}
</script>

<style scoped>

</style>
