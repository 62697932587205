<template>
  <div class="text-center">
    <div class="row no-gutters">
      <div class="col pb-2" v-if="$route.name === 'CompanyAuditDue'">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setCompaniesToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setCompaniesToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setCompaniesToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col" v-else>

      </div>
      <div class="col-3 pb-2" v-if="$route.name === 'CompanyAuditDue'">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="params.query" placeholder="Search Company">
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" :rowspan="$route.name !== 'CompanyAuditDue' ? 2 : 3" style="width: 1em" scope="col">#</th>
            <th class="align-middle cursor-pointer" :rowspan="$route.name !== 'CompanyAuditDue' ? 2 : 3" scope="col" @click="sorterArrowSetDirection('name')">
              <sorter-arrow
                :keyInList="'name'"
                :array-list-length="company_audit_dues.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              />
              COMPANY NAME
            </th>
            <th class="align-middle cursor-pointer" :rowspan="$route.name !== 'CompanyAuditDue' ? 2 : 3" scope="col" @click="sorterArrowSetDirection('country_id')">
              COUNTRY
              <sorter-arrow
                :keyInList="'country_id'"
                :array-list-length="company_audit_dues.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              />
            </th>
            <th class="align-middle cursor-pointer" :rowspan="$route.name !== 'CompanyAuditDue' ? 2 : 3" scope="col" @click="sorterArrowSetDirection('management_office_id')">
              MANAGEMENT
              <sorter-arrow
                :keyInList="'management_office_id'"
                :array-list-length="company_audit_dues.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              />
            </th>
            <th class="align-middle" :rowspan="$route.name !== 'CompanyAuditDue' ? 1 : 2" scope="col" colspan="4">PLAN</th>
            <th class="align-middle background-0000ff left-solid-border-black right-solid-border-black" colspan="3" scope="col">SMS</th>
          </tr>
          <tr v-if="$route.name === 'CompanyAuditDue'">
            <th colspan="3" class="p-0 left-solid-border-black right-solid-border-black">
              <div class="row col-12 m-auto p-0">
                <div class="col-4 m-0 p-0">
                  <data-counter
                    :display-text="'Overdue'"
                    :display-text-color="'#fffeff'"
                    :display-text-bg-color="'#f35656'"
                    :display-count="company_audit_dues_count.sms.overdue.count"
                    :display-count-color="'#fffeff'"
                    :display-count-bg-color="'#ff0000'"
                  />
                </div>
                <div class="col-4 m-0 p-0">
                  <data-counter
                    :display-text="'30 Days'"
                    :display-text-color="'#fffeff'"
                    :display-text-bg-color="'#ff8c00'"
                    :display-count="company_audit_dues_count.sms['30days'].count"
                    :display-count-color="'#fffeff'"
                    :display-count-bg-color="'#ffa500'"
                  />
                </div>
                <div class="col-4 m-0 p-0">
                  <data-counter
                    :display-text="'90 Days'"
                    :display-text-bg-color="'#fbfb98'"
                    :display-count="company_audit_dues_count.sms['90days'].count"
                    :display-count-bg-color="'#f3f309'"
                  />
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th>PLACE</th>
            <th>AUDITORS</th>
            <th>DATE</th>
            <th>REMARKS</th>

            <th class="left-solid-border-black">DATE OF LAST AUDIT</th>
            <th>NEXT DUE</th>
            <th class="right-solid-border-black">EXTENSION</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>

          <template v-else>
            <template v-for="(company, index) in company_audit_dues">
              <tr class="ia-company-list">
                <td class="align-middle">{{ index + pagination.from }}</td>
                <td class="align-middle text-uppercase">{{ company.name ?? 'SAMPLE COMPANY'}}</td>
<!--                <td class="align-middle text-uppercase">{{ company.vessel_imo_no?? '' }}</td>-->
                <td class="align-middle text-uppercase">{{ company.flag ?? '' }}</td>
                <td class="align-middle text-uppercase">{{ company.office ? company.office : '' }}</td>

                <td class="align-middle"></td>
                <td class="align-middle"></td>
                <td class="align-middle"></td>
                <td class="align-middle right-solid-border-black"></td>

                <td class="align-middle left-solid-border-black">{{ company.sms.last_audit_date === null ? '' : company.sms.last_audit_date.human }}</td>
                <td class="align-middle" :class="getClassByCount(company.id, 'sms')">{{ company.sms.next_due ?? ''}}</td>
                <td class="align-middle right-solid-border-black" :style="company.sms.extension === 1 ? 'color: red;' : ''">{{ company.sms.extension === null ? '' : company.sms.extension === 1 ? 'YES' : 'NO'}}</td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import SorterArrowMixin from "@/mixins/SorterArrowMixin";
import DataCounter from "@/components/common/DataCounter.vue";
import SorterArrow from "@/components/common/SorterArrow.vue";

export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin, SorterArrowMixin],
  components: {SorterArrow, DataCounter, AppLoading},

  name: 'CompanyAuditDue',
  data() {
    return {
      params: {
        active: 1,
        page:1,
        query: '',
        order_by : 'name',
        direction : 'asc'
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions([
      'getCompanyAuditDues',
      'getCompanyAuditDueCounts'
    ]),
    getClassByCount(company_id, type) {
      if (this.company_audit_dues_count[type].overdue.ids.includes(company_id) === true) {
        return 'overdue';
      }

      if (this.company_audit_dues_count[type]['30days'].ids.includes(company_id) === true) {
        return 'thirty_days';
      }

      if (this.company_audit_dues_count[type]['90days'].ids.includes(company_id) === true) {
        return 'ninety_days'
      }

      return '';

    },
    async setCompaniesToTable(page=1) {
      this.loading = true;
      this.params.page = page
      this.params.perPage = 40;
      if (this.$route.params.company_id) {
        await this.getCompanyAuditDues({
          company_id: this.$route.params.company_id
        })
      } else {
        await this.getCompanyAuditDues(this.params);
      }

      this.loading = false;
    },
    runCallBack(param) {
      this.params.order_by = param.orderBy;
      this.params.direction = param.direction;
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.setCompaniesToTable(1);
    }
  },
  computed: {
    ...mapGetters([
      'pagination',
      'isLoading',
      'company_audit_dues',
      'company_audit_dues_count'
    ])
  },
  async created() {
    this.loading = true;
    this.setCompanies = _.debounce(this.setCompaniesToTable, 750)
    await this.getCompanyAuditDueCounts();
    await this.setCompaniesToTable();
  },
  watch: {
    'params.active'() {
      this.setCompanies()
    },
    'params.query'() {
      this.setCompanies();
    }
  },

  destroyed() {
    this.params.active = 1;
  }

}
</script>

<style scoped>

.left-solid-border-black {
  border-left: 1px solid black !important;
}

.right-solid-border-black {
  border-right: 1px solid black !important;
}

.overdue {
  color: #fffeff;
  background-color: #ff0000;
}

.thirty_days {
  color: #fffeff;
  background-color: #ffa500;
}

.ninety_days {
  background-color: #f3f309;
}
</style>
